import { email, maxLength, numeric, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import type { ConsultationForm } from '@/composables/use-consultation/types';
import { $t } from '@/localization';

const NameRegEx = /^[a-z\s-]+$/i;

export function useConsultationValidator(params: ConsultationForm, isComingSoon?: boolean) {
  const validationRulesConsultation = {
    name: { required, maxLength: maxLength(100), nameCheck },
    phone_number: { required, maxLength: maxLength(20), numeric },
    email: { email },
  };

  const validationRulesComingSoon = {
    email: { email, required },
  };

  const v$ = useVuelidate(isComingSoon ? validationRulesComingSoon : validationRulesConsultation, params);

  function validate() {
    return v$.value.$validate();
  }

  function reset() {
    return v$.value.$reset();
  }

  function nameCheck(value: string): boolean {
    return NameRegEx.test(value);
  }

  const nameErrMessage = computed(() => {
    if (!v$.value.$dirty) {
      return undefined;
    }

    if (v$.value.name.required.$invalid) {
      return $t('enter-field', {
        fieldName: $t('consultation-name').toLowerCase(),
      });
    }

    if (v$.value.name.maxLength.$invalid) {
      return $t('filed-max-length', {
        fieldName: $t('consultation-name'),
        maxLength: v$.value.name.maxLength.$params.max,
      });
    }

    if (v$.value.name.nameCheck.$invalid) {
      return $t('use-letters', {
        fieldName: $t('consultation-name').toLowerCase(),
      });
    }
  });

  const phoneErrMessage = computed(() => {
    if (!v$.value.$dirty) {
      return undefined;
    }

    if (v$.value.phone_number.required.$invalid) {
      return $t('enter-field', {
        fieldName: $t('consultation-phone-num').toLowerCase(),
      });
    }

    if (v$.value.phone_number.maxLength.$invalid) {
      return $t('filed-max-length', {
        fieldName: $t('consultation-phone-num'),
        maxLength: v$.value.phone_number.maxLength.$params.max,
      });
    }

    if (v$.value.phone_number.numeric.$invalid) {
      return $t('use-digits', {
        fieldName: $t('consultation-phone-num').toLowerCase(),
      });
    }
  });

  const emailErrMessage = computed(() => {
    if (!v$.value.$dirty) {
      return undefined;
    }

    if (v$.value.email?.email.$invalid) {
      return $t('invalid-filed', {
        fieldName: $t('consultation-email').toLowerCase(),
      });
    }

    if (v$.value.email.required?.$invalid) {
      return $t('enter-field', {
        fieldName: $t('consultation-email').toLowerCase(),
      });
    }
  });

  return {
    v$,
    validate,
    reset,
    nameErrMessage,
    phoneErrMessage,
    emailErrMessage,
  };
}
