import type { ConsultationForm, ConsultationFormError, ConsultationResponse, WhatsappResponse } from './types';
import type { HttpResponseSuccess } from '@/shared/types';
import { isMobileDevice } from '@/shared/utils/device-detect';
import { useSingleProject } from '@/pages/single-project/composables';
import { $t } from '@/localization';
import ConsultationDrawer from '@/components/mobile/consultation-drawer/ConsultationDrawer.vue';
import InvestorsFeedback from '@/components/mobile/investors-feedback/InvestorsFeedback.vue';

export function useConsultation(isComingSoon?: boolean) {
  const { $api, $analytics } = useNuxtApp();
  const route = useRoute();
  const isMobile = isMobileDevice();
  const { showModal, hideModal } = useModal();
  const { showDrawer, hideDrawer } = useMobileDrawer();
  const { addToast } = useToasts();
  const { projectId } = useSingleProject();

  const formState = reactive<ConsultationForm>({
    name: '',
    phone_number: '',
    email: undefined,
  });

  const requestState = reactive({
    isLoading: false,
    success: false,
    errorMessage: '',
  });

  const { validate, nameErrMessage, phoneErrMessage, emailErrMessage } = useConsultationValidator(formState, isComingSoon);
  const errors = reactive<ConsultationFormError>({
    get nameErrMessage() {
      return nameErrMessage.value;
    },
    get phoneErrMessage() {
      return phoneErrMessage.value;
    },
    get emailErrMessage() {
      return emailErrMessage.value;
    },
  });

  const projectGTM = computed(() => ({
    project_id: route.params?.slug ? projectId.value : undefined, // id проекта
    project_slug: route.params?.slug, // slug проекта
  }));

  const resetFormState = (): void => {
    formState.name = '';
    formState.phone_number = '';
    formState.email = undefined;
  };

  const submitConsultationForm = async () => {
    const isValid = await validate();
    if (!isValid) {
      return;
    }
    const google_client_id = useCookie<string | undefined>('_ga');

    const body: ConsultationResponse = {
      ...formState,
      email: formState.email?.trim() || undefined,
      page_url: window.location.href,
      listing_id: 0, // TODO Replace with actual listing ID
      google_client_id: google_client_id.value,
    };
    requestState.isLoading = true;

    try {
      const { data, error } = await useApi<HttpResponseSuccess>('enquiry', {
        method: 'post',
        body,
      });

      // TODO show error message
      requestState.success = !!data.value?.success && !error.value;
      requestState.isLoading = false;

      if (data.value?.success) {
        resetFormState();

        $analytics.trackEvent('submit_enquire_form', 'click');
      }
    } catch (e) {
      requestState.success = false;
      requestState.isLoading = false;

      console.error('Error consultation form:', e);
    }
  };

  const submitInvestorForm = async () => {
    const isValid = await validate();

    if (!isValid) {
      return;
    }

    const body = {
      email: formState.email?.trim(),
    };

    try {
      const { data, error } = await useApi<HttpResponseSuccess>('/client/subscribe', {
        method: 'post',
        body,
      });

      requestState.success = !!data.value?.success && !error.value;
      requestState.isLoading = false;

      if (data.value?.success) {
        resetFormState();
      } else if (error.value) {
        requestState.errorMessage = 'Already subscribed';
      }
    } catch (e) {
      requestState.success = false;
      requestState.isLoading = false;
      console.error('Error investor form:', e);
    }
  };

  const showConsultationModal = (): void => {
    $analytics.trackEvent('open_request_consultation_form', 'click', projectGTM.value);

    if (isMobile) {
      showDrawer('consultation', {
        component: ConsultationDrawer,
        title: $t('consultation-title'),
        zOffset: 1000,
      });
      return;
    }

    const component = defineAsyncComponent(() => import('@/components/consultation-modal/ConsultationModal.vue'));
    showModal({ component });
  };

  const hideConsultationModal = (): void => {
    if (isMobile) {
      hideDrawer('consultation');
      return;
    }
    hideModal();
  };

  const showInvestorsFeedback = () => {
    showDrawer('investors-feedback', {
      component: InvestorsFeedback,
    });
  };

  const callHandler = (): void => {
    $analytics.trackEvent('call_to_agent', 'click', projectGTM.value);

    const aElement = document.createElement('a');
    aElement.href = `tel:+97145710421`;
    aElement.click();
    aElement.remove();
  };

  const whatsappHandler = async (name: 'project' | 'listing' = 'project') => {
    try {
      const sessionId = localStorage.getItem('ax-chat-uuid');
      if (!sessionId) {
        return;
      }

      const data = await fetchWhatsapp(name, sessionId);
      const phone = data.phone.replace('+', '');
      const url = `https://api.whatsapp.com/send/?phone=${phone}&text=${data.message}&type=phone_number&app_absent=0`;

      const gtmValue = {
        link: url, // ссылка на whatsapp
        request_code: data.token, // whatsapp code
        phone, // номер телефона в whatsapp
      };
      $analytics.trackEvent('click_whatsapp', 'click', gtmValue);

      window.location.href = url;
    } catch (e) {
      console.warn(e);
      addToast({
        label: $t('something-wrong'),
        type: 'error',
      });
    }
  };

  async function fetchWhatsapp(name: string, sessionId: string): Promise<WhatsappResponse> {
    const headers = new Headers();
    headers.set('x-session-id', sessionId);
    return await $api<WhatsappResponse>(`https://api.axcapital.ae/api/telecom/general/`, {
      headers,
      credentials: 'omit',
      method: 'POST',
      body: {
        name: `axmap.${name}`,
        mtp: 'wp',
        path: window.location.href,
      },
    });
  }

  return {
    formState,
    requestState,
    errors,
    showConsultationModal,
    hideConsultationModal,
    submitConsultationForm,
    showInvestorsFeedback,
    submitInvestorForm,
    callHandler,
    whatsappHandler,
  };
}
