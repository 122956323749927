import type { ConsultationForm } from '../use-consultation/types';

export function useAnalyticsFeedback(formState: ConsultationForm | Ref<ConsultationForm>) {
  const { $analytics } = useNuxtApp();

  const track = reactive({
    isName: false,
    isEmail: false,
    isPhone: false,
  });

  function onNameFilled() {
    if (!toValue(formState).name || track.isName) {
      return;
    }
    track.isName = true;
    $analytics.trackEvent('filled_name', 'fill');
  }
  function onEmailFilled() {
    if (!toValue(formState).email || track.isEmail) {
      return;
    }
    track.isEmail = true;
    $analytics.trackEvent('filled_email', 'fill');
  }
  function onPhoneFilled() {
    if (!toValue(formState).phone_number || track.isPhone) {
      return;
    }
    track.isPhone = true;
    $analytics.trackEvent('filled_phone', 'fill');
  }

  return {
    onNameFilled,
    onEmailFilled,
    onPhoneFilled,
  };
}
